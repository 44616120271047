import React from 'react';
import {
  Image,
  Input,
  Form,
  Radio,
  Dropdown,
  Table,
  Label,
  Icon,
} from 'semantic-ui-react';
import { ImageRemoveIcon, ImageWrapper } from '../../../../component/Image';
import * as S from './Style';
import { moduleType } from '../../../../constant/eventType';
import { screenOptions } from '../../../../constant/bannerOptions';
import { ColorSample } from '@component/ColorSample';
import { colors } from '../../../../constant/colors';
import moment from 'moment';
import { bannerTypeObj } from '../../../../constant/bannerTypes';
import FlexBox from '@component/FlexBox/FlexBox';
import RaceCouponModule from './modules/RaceCouponModule/RaceCouponModule';
import RaceCouponAllDownloadModule from './modules/RaceCouponAllDownloadModule/RaceCouponAllDownloadModule';
import PreAlarmListModule from './modules/PreAlarmListModule/PreAlarmListModule';

const EventModule = ({
  eventType,
  sectionIdx,
  moduleIdx,
  module,
  challengeOptions,
  goalOptions,
  raceOptions,
  colorOptions,
  eventOptions,
  banners,
  handleChangeFile,
  removeImage,
  handleChange,
  handleRadioChange,
}) => {
  switch (module.name) {
    case moduleType.imageList:
      return (
        <ImageList
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChangeFile={handleChangeFile}
          removeImage={removeImage}
        />
      );
    case moduleType.countDown:
      return (
        <Countdown
          colorOptions={colorOptions}
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
          handleRadioChange={handleRadioChange}
        />
      );
    case moduleType.billBoard:
      return (
        <BillBoard
          eventType={eventType}
          colorOptions={colorOptions}
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
          handleRadioChange={handleRadioChange}
        />
      );
    case moduleType.sectionTitle:
      return (
        <SectionTitle
          colorOptions={colorOptions}
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
          handleRadioChange={handleRadioChange}
        />
      );
    case moduleType.itemList:
      return (
        <ItemList
          eventType={eventType}
          challengeOptions={challengeOptions}
          goalOptions={goalOptions}
          raceOptions={raceOptions}
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleRadioChange={handleRadioChange}
        />
      );
    case moduleType.bannerList:
      return (
        <BannerList
          eventType={eventType}
          banners={banners}
          colorOptions={colorOptions}
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
          handleRadioChange={handleRadioChange}
        />
      );
    case moduleType.button:
      return (
        <Button
          challengeOptions={challengeOptions}
          raceOptions={raceOptions}
          eventOptions={eventOptions}
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
          handleRadioChange={handleRadioChange}
        />
      );
    case moduleType.shareBoard:
      return (
        <ShareBoard
          colorOptions={colorOptions}
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
          handleRadioChange={handleRadioChange}
        />
      );
    case moduleType.nativeShareButton:
      return (
        <NativeShareButton
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
        />
      );
    case moduleType.divider:
      return (
        <Divider
          colorOptions={colorOptions}
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleRadioChange={handleRadioChange}
        />
      );
    case moduleType.youtubePlayer:
      return (
        <YoutubePlayer
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
        />
      );
    case moduleType.raceCoupon:
      return (
        <RaceCouponModule
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
        />
      );
    case moduleType.raceCouponAllDownload:
      return (
        <RaceCouponAllDownloadModule
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
        />
      );
    case moduleType.preAlarmList:
      return (
        <PreAlarmListModule
          sectionIdx={sectionIdx}
          moduleIdx={moduleIdx}
          module={module}
          handleChange={handleChange}
        />
      );
    default:
      return null;
  }
};

export default EventModule;

const ImageList = ({
  sectionIdx,
  moduleIdx,
  module,
  handleChangeFile,
  removeImage,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 이미지 모듈</h4>
      <Form.Field>
        <label>이미지를 업로드하세요</label>
        <Input
          type="file"
          name={`${sectionIdx}-${moduleIdx}-imageUrlList`}
          onChange={handleChangeFile}
        />
        {module.data.imageUrlList?.length > 0 && (
          <S.RowContainer>
            {module.data.imageUrlList.map((url, idx) => (
              <ImageWrapper key={url}>
                <Image src={url} size="small" />
                <ImageRemoveIcon
                  top={'0px'}
                  left={'0px'}
                  onClick={() => removeImage(sectionIdx, moduleIdx, idx)}
                />
              </ImageWrapper>
            ))}
          </S.RowContainer>
        )}
      </Form.Field>
    </>
  );
};

const Countdown = ({
  colorOptions,
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
  handleRadioChange,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 카운트다운 모듈</h4>
      <Form.Field>
        <label>텍스트 (시간 위에 노출되는 글씨)</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-text`}
          value={module.data.text}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>글씨색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.textColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-textColor`}
            value={module.data.textColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>배경색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.bgColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-bgColor`}
            value={module.data.bgColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>D-day 시점</label>
        <Input
          type="datetime-local"
          max="9999-12-31T23:59"
          name={`${sectionIdx}-${moduleIdx}-expiredDate`}
          value={module.data.expiredDate}
          onChange={handleRadioChange}
        />
      </Form.Field>
    </>
  );
};

const BillBoard = ({
  eventType,
  colorOptions,
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
  handleRadioChange,
}) => {
  return (
    <>
      <h4>
        [모듈 {moduleIdx + 1}]{' '}
        {eventType === 'pill_product' ? '상품 구매자' : '챌린지 참가자'} 숫자
        모듈
      </h4>
      <Form.Field>
        <label>텍스트 (예시: {'현재 ${count}명이 구매하고 있어요.'})</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-text`}
          value={module.data.text}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>글씨색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.textColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-textColor`}
            value={module.data.textColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>숫자 강조색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.countColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-countColor`}
            value={module.data.countColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>배경색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.bgColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-bgColor`}
            value={module.data.bgColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
    </>
  );
};

const SectionTitle = ({
  colorOptions,
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
  handleRadioChange,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 타이틀 모듈</h4>
      <Form.Field>
        <label>타이틀 텍스트</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-title`}
          value={module.data.title}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>타이틀 글씨색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.titleColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-titleColor`}
            value={module.data.titleColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>서브타이틀 텍스트</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-subtitle`}
          value={module.data.subtitle}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>서브타이틀 글씨색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.subtitleColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-subtitleColor`}
            value={module.data.subtitleColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>배경색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.bgColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-bgColor`}
            value={module.data.bgColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
    </>
  );
};

const ItemList = ({
  eventType,
  challengeOptions,
  goalOptions,
  raceOptions,
  sectionIdx,
  moduleIdx,
  module,
  handleRadioChange,
}) => {
  const challengeMap = challengeOptions.reduce((acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  }, {});

  const goalMap = goalOptions.reduce((acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  }, {});

  const raceMap = raceOptions.reduce((acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  }, {});

  const changeOrder = (targetType, direction, idx) => {
    let targeIds = [];
    switch (targetType) {
      case 'raceIds':
        targeIds = [...module.data.raceIds];
        break;
      case 'challengeIDs':
        targeIds = [...module.data.challengeIDs];
        break;
      case 'goalIDs':
        targeIds = [...module.data.goalIDs];
        break;
      default:
        return;
    }

    const removed = targeIds.splice(idx, 1);
    targeIds.splice(direction === 'up' ? idx - 1 : idx + 1, 0, removed[0]);

    handleRadioChange(null, {
      name: `${sectionIdx}-${moduleIdx}-${targetType}`,
      value: targeIds,
    });
  };

  const handleClickDelete = (targetType, targetId) => {
    let targeIds = [];
    switch (targetType) {
      case 'raceIds':
        targeIds = [...module.data.raceIds];
        break;
      case 'challengeIDs':
        targeIds = [...module.data.challengeIDs];
        break;
      case 'goalIDs':
        targeIds = [...module.data.goalIDs];
        break;
      default:
        return;
    }

    targeIds = targeIds.filter((raceId) => raceId !== targetId);
    handleRadioChange(null, {
      name: `${sectionIdx}-${moduleIdx}-${targetType}`,
      value: targeIds,
    });
  };

  return (
    <>
      <h4>
        [모듈 {moduleIdx + 1}]{' '}
        {eventType === 'race' ? '랜선대회' : '챌린지/목표'} 모듈
      </h4>
      {eventType === 'race' ? (
        <>
          <Form.Field>
            <label>랜선 대회를 선택하세요.</label>
            <Dropdown
              key={'INDIVIDUAL'}
              placeholder="상품을 선택하세요."
              fluid
              selection
              multiple
              search
              options={raceOptions}
              name={`${sectionIdx}-${moduleIdx}-raceIds`}
              value={module.data.raceIds}
              onChange={handleRadioChange}
            />
          </Form.Field>

          <h5>노출 순서</h5>
          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>대회명</Table.HeaderCell>
                <Table.HeaderCell>순서변경</Table.HeaderCell>
                <Table.HeaderCell>삭제</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {module.data.raceIds?.map((raceId, idx) => (
                <Table.Row key={raceId}>
                  <Table.Cell>{raceMap[raceId]?.text}</Table.Cell>
                  <Table.Cell>
                    <Label onClick={() => changeOrder('raceIds', 'up', idx)}>
                      <Icon name="angle up" style={{ margin: 0 }} />
                    </Label>
                    <Label onClick={() => changeOrder('raceIds', 'down', idx)}>
                      <Icon name="angle down" style={{ margin: 0 }} />
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <Label onClick={() => handleClickDelete('raceIds', raceId)}>
                      <Icon name="close" style={{ margin: 0 }} />
                    </Label>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      ) : (
        <>
          <Form.Group grouped>
            <label>챌린지/목표 목록 기준</label>
            <Form.Field
              control={Radio}
              label="챌린지"
              value="CHALLENGE"
              name={`${sectionIdx}-${moduleIdx}-challengeListType`}
              checked={module.data.challengeListType === 'CHALLENGE'}
              onChange={handleRadioChange}
            />
            <Form.Field
              control={Radio}
              label="목표"
              value="GOAL"
              name={`${sectionIdx}-${moduleIdx}-challengeListType`}
              checked={module.data.challengeListType === 'GOAL'}
              onChange={handleRadioChange}
            />

            {module.data.challengeListType === 'CHALLENGE' ? (
              <>
                <Form.Field>
                  <label>챌린지 목록</label>
                  <Dropdown
                    key={'CHALLENGE'}
                    placeholder="챌린지를 선택하세요."
                    fluid
                    selection
                    multiple
                    search
                    options={challengeOptions}
                    name={`${sectionIdx}-${moduleIdx}-challengeIDs`}
                    value={module.data.challengeIDs}
                    onChange={handleRadioChange}
                  />
                </Form.Field>

                <h5>노출 순서</h5>
                <Table basic="very" size="small">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>챌린지명</Table.HeaderCell>
                      <Table.HeaderCell>순서변경</Table.HeaderCell>
                      <Table.HeaderCell>삭제</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {module.data.challengeIDs?.map((challengeId, idx) => (
                      <Table.Row key={challengeId}>
                        <Table.Cell>
                          {challengeMap[challengeId]?.text}
                        </Table.Cell>
                        <Table.Cell>
                          <Label
                            onClick={() =>
                              changeOrder('challengeIDs', 'up', idx)
                            }
                          >
                            <Icon name="angle up" style={{ margin: 0 }} />
                          </Label>
                          <Label
                            onClick={() =>
                              changeOrder('challengeIDs', 'down', idx)
                            }
                          >
                            <Icon name="angle down" style={{ margin: 0 }} />
                          </Label>
                        </Table.Cell>
                        <Table.Cell>
                          <Label
                            onClick={() =>
                              handleClickDelete('challengeIDs', challengeId)
                            }
                          >
                            <Icon name="close" style={{ margin: 0 }} />
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </>
            ) : (
              <>
                <Form.Field>
                  <label>목표 목록</label>
                  <Dropdown
                    key={'GOAL'}
                    placeholder="목표를 선택하세요."
                    fluid
                    selection
                    multiple
                    search
                    options={goalOptions}
                    name={`${sectionIdx}-${moduleIdx}-goalIDs`}
                    value={module.data.goalIDs}
                    onChange={handleRadioChange}
                  />
                </Form.Field>

                <h5>노출 순서</h5>
                <Table basic="very" size="small">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>목표명</Table.HeaderCell>
                      <Table.HeaderCell>순서변경</Table.HeaderCell>
                      <Table.HeaderCell>삭제</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {module.data.goalIDs?.map((goalId, idx) => (
                      <Table.Row key={goalId}>
                        <Table.Cell>{goalMap[goalId]?.text}</Table.Cell>
                        <Table.Cell>
                          <Label
                            onClick={() => changeOrder('goalIDs', 'up', idx)}
                          >
                            <Icon name="angle up" style={{ margin: 0 }} />
                          </Label>
                          <Label
                            onClick={() => changeOrder('goalIDs', 'down', idx)}
                          >
                            <Icon name="angle down" style={{ margin: 0 }} />
                          </Label>
                        </Table.Cell>
                        <Table.Cell>
                          <Label
                            onClick={() => handleClickDelete('goalIDs', goalId)}
                          >
                            <Icon name="close" style={{ margin: 0 }} />
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </>
            )}
          </Form.Group>
        </>
      )}
    </>
  );
};

const BannerList = ({
  eventType,
  banners,
  colorOptions,
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
  handleRadioChange,
}) => {
  const bannerOptions = [];
  banners.forEach((b) => {
    if (
      (['challenge', 'challenge_information'].includes(eventType) &&
        b.bannerType === bannerTypeObj.challengeMain.value) ||
      (['pill_product', 'store_information'].includes(eventType) &&
        b.bannerType === bannerTypeObj.storeMain.value)
    ) {
      bannerOptions.push({
        key: b.id,
        text: `[${b.id}] ${b.title} (${moment(b.startDatetime).format(
          'YYYY-MM-DD',
        )}~${moment(b.endDatetime).format('YYYY-MM-DD')})`,
        value: b.id,
        image: { avatar: false, src: b.imageUrl },
      });
    }
  });

  const filteredBanners = [];
  if (module.data.bannerIDs?.length > 0) {
    module.data.bannerIDs.forEach((id) => {
      const banner = banners.find((b) => b.id === id);
      if (banner) filteredBanners.push(banner);
    });
  }

  const changeImageOrder = (e, { name, value }) => {
    const _bannerIds = [...module.data.bannerIDs];

    if (name === 'up') {
      const removed = _bannerIds.splice(value, 1);
      _bannerIds.splice(value - 1, 0, removed[0]);
    } else {
      const removed = _bannerIds.splice(value, 1);
      _bannerIds.splice(value + 1, 0, removed[0]);
    }

    handleRadioChange(null, {
      name: `${sectionIdx}-${moduleIdx}-bannerIDs`,
      value: _bannerIds,
    });
  };

  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 배너 목록 모듈</h4>
      <Form.Field>
        <label>타이틀 텍스트</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-title`}
          value={module.data.title}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>타이틀 글씨색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.titleColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-titleColor`}
            value={module.data.titleColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>배경색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.bgColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-bgColor`}
            value={module.data.bgColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>배너 목록 선택</label>
        <Dropdown
          key={'BANNER'}
          placeholder="목록에 노출할 배너를 선택하세요."
          fluid
          selection
          multiple
          search
          options={bannerOptions}
          name={`${sectionIdx}-${moduleIdx}-bannerIDs`}
          value={module.data.bannerIDs}
          onChange={handleRadioChange}
        />
      </Form.Field>

      {filteredBanners.length > 0 && (
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>랜딩타입</Table.HeaderCell>
              <Table.HeaderCell>제목</Table.HeaderCell>
              <Table.HeaderCell>부제</Table.HeaderCell>
              <Table.HeaderCell>이미지</Table.HeaderCell>
              <Table.HeaderCell>글씨색</Table.HeaderCell>
              <Table.HeaderCell>시작일</Table.HeaderCell>
              <Table.HeaderCell>종료일</Table.HeaderCell>
              <Table.HeaderCell>공개방식</Table.HeaderCell>
              <Table.HeaderCell>노출상태</Table.HeaderCell>
              <Table.HeaderCell>순서변경</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredBanners.map((b, idx) => {
              return (
                <Table.Row key={b.id}>
                  <Table.Cell>{b.id}</Table.Cell>
                  <Table.Cell>{b.landingType}</Table.Cell>
                  <Table.Cell>{b.title}</Table.Cell>
                  <Table.Cell>{b.subtitle}</Table.Cell>
                  <Table.Cell>
                    <Image size={'small'} src={b.imageUrl} />{' '}
                  </Table.Cell>
                  <Table.Cell>{b.color}</Table.Cell>
                  <Table.Cell>
                    {moment(b.startDatetime).format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(b.endDatetime).format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{b.displayType}</Table.Cell>
                  <Table.Cell>{b.isDisplayed ? '노출' : '미노출'}</Table.Cell>
                  <Table.Cell>
                    <Label name="up" value={idx} onClick={changeImageOrder}>
                      <Icon name="angle up" style={{ margin: 0 }} />
                    </Label>
                    <Label name="down" value={idx} onClick={changeImageOrder}>
                      <Icon name="angle down" style={{ margin: 0 }} />
                    </Label>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

const Button = ({
  challengeOptions,
  raceOptions,
  eventOptions,
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
  handleRadioChange,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 버튼 모듈</h4>
      <Form.Field>
        <label>버튼 텍스트</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-text`}
          value={module.data.text}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Group grouped>
        <label>버튼 액션</label>
        <Form.Field
          control={Radio}
          label="챌린지로 이동"
          value="ROUTE_CHALLENGE"
          name={`${sectionIdx}-${moduleIdx}-action`}
          checked={module.data.action === 'ROUTE_CHALLENGE'}
          onChange={handleRadioChange}
        />
        <Form.Field
          control={Radio}
          label="앱 화면으로 이동"
          value="ROUTE_APP_SCREEN"
          name={`${sectionIdx}-${moduleIdx}-action`}
          checked={module.data.action === 'ROUTE_APP_SCREEN'}
          onChange={handleRadioChange}
        />
        <Form.Field
          control={Radio}
          label="웹 페이지로 이동"
          value="ROUTE_WEB"
          name={`${sectionIdx}-${moduleIdx}-action`}
          checked={module.data.action === 'ROUTE_WEB'}
          onChange={handleRadioChange}
        />

        <Form.Field>
          <label>액션 도착지</label>
          {module.data.action === 'ROUTE_CHALLENGE' ? (
            <Dropdown
              key={'CHALLENGE'}
              placeholder="챌린지를 선택하세요."
              clearable
              fluid
              selection
              search
              options={challengeOptions}
              name={`${sectionIdx}-${moduleIdx}-challengeID`}
              value={module.data.challengeID}
              onChange={handleRadioChange}
            />
          ) : module.data.action === 'ROUTE_APP_SCREEN' ? (
            <>
              <Dropdown
                placeholder="이동할 화면을 선택하세요."
                clearable
                fluid
                search
                selection
                name={`${sectionIdx}-${moduleIdx}-routeName`}
                options={screenOptions}
                value={module.data.routeName}
                onChange={handleRadioChange}
              />

              <RouteAppScreenParams
                data={module.data}
                raceOptions={raceOptions}
                eventOptions={eventOptions}
                sectionIdx={sectionIdx}
                moduleIdx={moduleIdx}
                handleChange={handleChange}
                handleRadioChange={handleRadioChange}
              />
            </>
          ) : (
            <>
              <Input
                placeholder={'url을 입력하세요.'}
                name={`${sectionIdx}-${moduleIdx}-url`}
                value={module.data.url}
                onChange={handleChange}
              />
              <label>웹뷰 타이틀</label>
              <label>(입력해야 상단바 노출 됨)</label>
              <Input
                placeholder={'타이틀을 입력하세요.'}
                name={`${sectionIdx}-${moduleIdx}-title`}
                value={module.data.title}
                onChange={handleChange}
              />
            </>
          )}
        </Form.Field>
      </Form.Group>
    </>
  );
};

const RouteAppScreenParams = ({
  data,
  raceOptions,
  eventOptions,
  sectionIdx,
  moduleIdx,
  handleChange,
  handleRadioChange,
}) => {
  switch (data.routeName) {
    case 'EventScreen':
      return (
        <Form.Field>
          <label>이벤트 페이지를 선택하세요</label>
          <Dropdown
            placeholder="원하는 이벤트 페이지를 선택해주세요."
            fluid
            search
            selection
            name={
              sectionIdx >= 0
                ? `${sectionIdx}-${moduleIdx}-eventName`
                : 'eventName'
            }
            options={eventOptions}
            value={data.eventName}
            onChange={handleRadioChange}
          />
        </Form.Field>
      );
    case 'RaceRegisterScreen':
      return (
        <Form.Field>
          <label>원하는 랜선대회를 선택하세요</label>
          <Dropdown
            placeholder="원하는 랜선대회를 선택해주세요."
            fluid
            search
            selection
            name={
              sectionIdx >= 0 ? `${sectionIdx}-${moduleIdx}-raceId` : 'raceId'
            }
            options={raceOptions}
            value={data.payload?.raceId}
            onChange={handleRadioChange}
          />
        </Form.Field>
      );
    default:
      return null;
  }
};

const ShareBoard = ({
  colorOptions,
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
  handleRadioChange,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 공유하기 모듈 (웹)</h4>
      <Form.Field>
        <label>타이틀 텍스트</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-title`}
          value={module.data.title}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>타이틀 글씨색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.titleColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-titleColor`}
            value={module.data.titleColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>서브타이틀 텍스트</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-subtitle`}
          value={module.data.subtitle}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>서브타이틀 글씨색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.subtitleColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-subtitleColor`}
            value={module.data.subtitleColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
      <Form.Field>
        <label>배경색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.bgColor]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-bgColor`}
            value={module.data.bgColor}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
    </>
  );
};

const NativeShareButton = ({ sectionIdx, moduleIdx, module, handleChange }) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 공유하기 버튼 (앱)</h4>
      <Form.Field>
        <label>타이틀 텍스트</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-title`}
          value={module.data.title}
          onChange={handleChange}
        />
      </Form.Field>
    </>
  );
};

const Divider = ({
  colorOptions,
  sectionIdx,
  moduleIdx,
  module,
  handleRadioChange,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 구분선 모듈</h4>
      <Form.Group grouped>
        <label>선 두께</label>
        <Form.Field
          control={Radio}
          label="얇음 (1px)"
          value="thin"
          name={`${sectionIdx}-${moduleIdx}-style`}
          checked={module.data.style === 'thin'}
          onChange={handleRadioChange}
        />
        <Form.Field
          control={Radio}
          label="두꺼움 (10px)"
          value="thick"
          name={`${sectionIdx}-${moduleIdx}-style`}
          checked={module.data.style === 'thick'}
          onChange={handleRadioChange}
        />
      </Form.Group>
      <Form.Field>
        <label>선 색</label>
        <S.RowContainer>
          <ColorSample color={colors[module.data.color]} />
          <Dropdown
            placeholder="색깔을 선택하세요."
            fluid
            selection
            search
            options={colorOptions}
            name={`${sectionIdx}-${moduleIdx}-color`}
            value={module.data.color}
            onChange={handleRadioChange}
          />
        </S.RowContainer>
      </Form.Field>
    </>
  );
};

export const CTAButton = ({
  ctaButton,
  challengeOptions,
  raceOptions,
  eventOptions,
  colorOptions,
  handleChange,
  handleRadioChange,
}) => {
  return (
    <>
      <h4>플로팅 버튼 세팅</h4>
      <Form.Field>
        <label>버튼 텍스트</label>
        <Input name={'text'} value={ctaButton.text} onChange={handleChange} />
      </Form.Field>
      <FlexBox.Row>
        <div style={{ flex: 1 }}>
          <Form.Field>
            <label>버튼 글씨색 (#FFFFFF) </label>
            <ColorSample color={ctaButton.textColor} />
            <input
              name={'textColor'}
              value={ctaButton.textColor}
              onChange={handleChange}
            />
          </Form.Field>
        </div>
        <div style={{ flex: 1 }}>
          <Form.Field>
            <label>버튼 배경색</label>
            <ColorSample color={ctaButton.backgroundColor} />
            <input
              name={'backgroundColor'}
              value={ctaButton.backgroundColor}
              onChange={handleChange}
            />
          </Form.Field>
        </div>
      </FlexBox.Row>
      <Form.Group grouped>
        <label>버튼 액션</label>
        <Form.Field
          control={Radio}
          label="챌린지로 이동"
          value="ROUTE_CHALLENGE"
          name={'action'}
          checked={ctaButton.action === 'ROUTE_CHALLENGE'}
          onChange={handleRadioChange}
        />
        <Form.Field
          control={Radio}
          label="앱 화면으로 이동"
          value="ROUTE_APP_SCREEN"
          name={'action'}
          checked={ctaButton.action === 'ROUTE_APP_SCREEN'}
          onChange={handleRadioChange}
        />
        <Form.Field
          control={Radio}
          label="웹 페이지로 이동"
          value="ROUTE_WEB"
          name={'action'}
          checked={ctaButton.action === 'ROUTE_WEB'}
          onChange={handleRadioChange}
        />

        <Form.Field>
          <label>액션 도착지</label>
          {ctaButton.action === 'ROUTE_CHALLENGE' ? (
            <Dropdown
              key={'CHALLENGE'}
              placeholder="챌린지를 선택하세요."
              clearable
              fluid
              selection
              search
              options={challengeOptions}
              name={'challengeID'}
              value={ctaButton.challengeID}
              onChange={handleRadioChange}
            />
          ) : ctaButton.action === 'ROUTE_APP_SCREEN' ? (
            <>
              <Dropdown
                placeholder="이동할 화면을 선택하세요."
                clearable
                fluid
                selection
                name={'routeName'}
                options={screenOptions}
                value={ctaButton.routeName}
                onChange={handleRadioChange}
              />

              {/* 이동할 스크린이 브랜드/판매원이라면 추가 옵션 선택 필요 */}
              <RouteAppScreenParams
                data={ctaButton}
                eventOptions={eventOptions}
                raceOptions={raceOptions}
                sectionIdx={-1}
                moduleIdx={-1}
                handleChange={handleChange}
                handleRadioChange={handleRadioChange}
              />
            </>
          ) : (
            <Input
              placeholder={'url을 입력하세요.'}
              name={'url'}
              value={ctaButton.url}
              onChange={handleChange}
            />
          )}
        </Form.Field>
      </Form.Group>
    </>
  );
};

const YoutubePlayer = ({ sectionIdx, moduleIdx, module, handleChange }) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 유튜브 모듈</h4>
      <Form.Field>
        <label>유튜브 id</label>
        <Input
          name={`${sectionIdx}-${moduleIdx}-youtubeId`}
          value={module.data.youtubeId}
          onChange={handleChange}
        />
      </Form.Field>
    </>
  );
};
