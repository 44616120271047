import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Input,
} from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import api, { apiGo, apiMedia, setAuthToken, updateUser } from '../../api';
import axios from 'axios';
import parseToken from '../../global/parseToken';
import { setRoles, setToken, setUser } from '../App/App.action';
import { setLocal } from '../../global/local';
import { useDispatch } from 'react-redux';

const PasswordChange = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRetry, setNewPasswordRetry] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // 비밀번호 난이도 체크
    const regularExpression =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&|])[A-Za-z\d@$!%*#?&|]{8,16}$/;
    const data = [
      { name: 'newPassword', value: newPassword },
      { name: 'newPasswordRetry', value: newPasswordRetry },
    ];
    const _errorMessage = { ...errorMessage };
    let _disabled = false;
    data.map((item) => {
      if (item.value.length === 0) return;
      if (!regularExpression.test(item.value)) {
        _errorMessage[item.name] =
          '비밀번호는 영문/숫자/특수문자를 조합한 8자~16자여야 합니다.';
        _disabled = true;
      } else {
        _errorMessage[item.name] = '';
      }
    });

    if (newPasswordRetry.length > 0) {
      if (newPassword !== newPasswordRetry) {
        _errorMessage['newPasswordRetry'] =
          '입력하신 새 비밀번호가 일치하지 않습니다.';
        _disabled = true;
      } else {
        _errorMessage['newPasswordRetry'] = '';
      }
    } else {
      _disabled = true;
    }

    setErrorMessage(_errorMessage);
    setDisabled(_disabled);
  }, [newPassword, newPasswordRetry]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'password':
        return setPassword(value);
      case 'newPassword':
        return setNewPassword(value);
      case 'newPasswordRetry':
        return setNewPasswordRetry(value);
    }
  };

  const submit = async () => {
    if (newPassword !== newPasswordRetry) {
      return alert('신규 패스워드가 일치하지 않습니다.');
    }

    try {
      if (state?.from === 'expired') {
        const res = await axios.put(
          'https://chlngers-admin.whitecube.co.kr/api/admin/users/me',
          { password, newPassword },
          { headers: { Authorization: state.token } },
        );
        const { user } = res.data.data;
        const tokenData = parseToken(state.token);
        setAuthToken(api, state.token);
        setAuthToken(apiMedia, state.token);
        setAuthToken(apiGo, state.token);
        dispatch(setToken(state.token));
        dispatch(setRoles(tokenData.roles));
        dispatch(setUser(user));
        setLocal('newToken', state.token);
      } else {
        await updateUser({ password, newPassword });
      }
    } catch (e) {
      alert('[' + e.response.status + '] ' + e.response.data.userMessage);
    }
    alert('비밀번호 변경이 완료되었습니다.');
    setPassword('');
    setNewPassword('');
    setNewPasswordRetry('');
    setDisabled(true);
    setErrorMessage({});
    history.replace('/home');
  };

  return (
    <Container style={styles.container}>
      <Grid columns={3}>
        <Grid.Column />
        <Grid.Column>
          {state?.from === 'expired' && (
            <h3>
              비밀번호를 변경한지 한 달이 지나 새로운 비밀번호 설정이
              필요합니다.
            </h3>
          )}
          <Divider hidden />

          <Form onSubmit={submit}>
            <Form.Field>
              <label>기존 비밀번호</label>
              <Input
                icon="user secret"
                iconPosition="left"
                type="password"
                placeholder="기존에 사용했던 비밀번호"
                name="password"
                value={password}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>새 비밀번호</label>
              <Input
                icon="user secret"
                iconPosition="left"
                type="password"
                placeholder="영문/숫자/특수문자를 조합한 8자~16자"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
              />
              <span style={{ color: 'red' }}>
                {errorMessage['newPassword']}
              </span>
            </Form.Field>
            <Form.Field>
              <label>새 비밀번호 (재입력)</label>
              <Input
                icon="user secret"
                iconPosition="left"
                type="password"
                placeholder="영문/숫자/특수문자를 조합한 8자~16자"
                name="newPasswordRetry"
                value={newPasswordRetry}
                onChange={handleChange}
              />
              <span style={{ color: 'red' }}>
                {errorMessage['newPasswordRetry']}
              </span>
            </Form.Field>
            <Button
              fluid
              disabled={disabled}
              type="submit"
              color={disabled ? 'grey' : 'red'}
            >
              변경하기
            </Button>
          </Form>
        </Grid.Column>
        <Grid.Column />
      </Grid>
    </Container>
  );
};

export default PasswordChange;

const styles = {
  container: {
    marginTop: 50,
  },
};
