const landingTypeOptions = [
  { key: 1, text: '[앱]챌린지', value: 'CHALLENGE' },
  { key: 2, text: '[앱]컨텐츠', value: 'CONTENT' },
  { key: 3, text: '웹', value: 'WEB' },
  { key: 4, text: '[앱]공지', value: 'NOTICE' },
  { key: 5, text: '[앱]마이페이지', value: 'MyPageTab' },
  { key: 6, text: '[앱]주문/배송관리', value: 'MyOrderDeliveryScreen' },
  { key: 7, text: '[앱]이벤트 페이지', value: 'APP_EVENT_SCREEN' },
  { key: 8, text: '[앱]그 외 화면', value: 'APP_ROUTING' },
  { key: 9, text: '[웹] 외부 브라우저로 이동', value: 'EXTERNAL_WEB' },
];

const etcScreensForLandingDetail = [
  { key: 1, text: '[앱]친구초대 페이지', value: 'InviteFriendScreen' },
  { key: 2, text: '[앱]대회 신청화면', value: 'RaceRegisterScreen' },
  {
    key: 3,
    text: '[앱]대회 둘러보기 화면',
    value: 'RaceProgressLookAroundScreen',
  },
  { key: 5, text: '[앱]혜택 탭', value: 'BenefitTab' },
  { key: 6, text: '[앱]오퍼월 브릿지 페이지', value: 'OfferWallBridgeScreen' },
  { key: 9, text: '[앱]뽑기', value: 'GotchaLineUpScreen' },
  {
    key: 12,
    text: '[앱]바이럴루프 이벤트 페이지',
    value: 'ViralLoopEventScreen',
  },
];

const displayTypeOptions = [
  { key: 1, text: '전체', value: 'ALL' },
  { key: 2, text: '신규(챌린지)', value: 'NEW_CHALLENGE' },
  { key: 3, text: '기존(챌린지)', value: 'OLD_CHALLENGE' },
  { key: 6, text: '제휴', value: 'COLLABORATION' },
  { key: 7, text: '어드민', value: 'ADMIN' },
];

const myOrderDeliveryScreenOptions = [
  { key: 2, text: '주문내역-챌린지', value: 'CHALLENGE' },
];

const screenOptions = [
  { key: 1, text: '마이페이지', value: 'MyPageTab' },
  {
    key: 4,
    text: '주문/배송관리(챌린지)',
    value: 'MyOrderDeliveryScreen-CHALLENGE',
  },
  { key: 8, text: '친구초대 페이지', value: 'InviteFriendScreen' },
  { key: 9, text: '보유상금 페이지', value: 'PrizeScreen' },
  { key: 10, text: '[앱]이벤트 페이지', value: 'EventScreen' },
  { key: 11, text: '[앱]대회 신청 화면', value: 'RaceRegisterScreen' },
  { key: 12, text: '[앱]혜택탭', value: 'BenefitTab' },
  { key: 13, text: '[앱]버전 정보 스크린', value: 'VersionInformationScreen' },
  { key: 14, text: '[앱]랜선 대회 모음', value: 'RaceCategoryScreen' },
  { key: 16, text: '[앱]랜선 100원딜 모음', value: 'Race100WonListScreen' },
  {
    key: 17,
    text: '[앱]사전 알림 리스트 스크린',
    value: 'PreAlarmListScreen',
  },
];

const benefitActionTypeOptions = [
  { key: 1, text: '사이트 3초 보고', value: 'VISIT_WEBSITE' },
  { key: 2, text: '챌린지 구경하고', value: 'VISIT_CHALLENGE' },
  { key: 3, text: '랜선대회 구경하고', value: 'VISIT_RACE' },
];

export {
  landingTypeOptions,
  etcScreensForLandingDetail,
  displayTypeOptions,
  myOrderDeliveryScreenOptions,
  screenOptions,
  benefitActionTypeOptions,
};
