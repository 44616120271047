import React from 'react';
import { Dropdown, Image, Label, Table } from 'semantic-ui-react';
import { colors } from '../../../../../constant/colors';
import { sponsorTypeOptions } from '../../../../../constant/race';
import { generateId } from '../../../../../utils/number';
import { handleChangeSingleFile } from '../../../../../utils/uploadImage';
import LogoPreview from './LogoPreview/LogoPreview';
import WhiteLogoPreview from './WhiteLogoPreview/WhiteLogoPreview';
import FilterPreview from './FilterPreview/FilterPreview';
import { Row } from '../../../../../component/Row';
import {
  RaceImageFilter,
  RaceImageFilterLogoStamp,
} from 'src/types/race.types';
import _ from 'lodash';
import { RaceFormType } from '../../RaceForm.types';
import { Race } from '@types';

const SponsorField = ({
  race,
  setRace,
  imageFilters = [],
  setImageFilters,
}: {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
  imageFilters: RaceImageFilter[];
  setImageFilters: React.Dispatch<React.SetStateAction<RaceImageFilter[]>>;
}) => {
  const addSponsor = () => {
    const _race = _.cloneDeep(race);
    _race.sponsors.push({
      id: generateId(),
      name: '',
      type: 'main',
      logoImageUrl: '',
      whiteLogoImageUrl: '',
    });
    setRace(_race);
  };

  const deleteSponsor = (idx: number) => {
    const _race = _.cloneDeep(race);
    _race.sponsors.splice(idx, 1);
    setRace(_race);
  };

  const handleSponsorChange = <K extends keyof Race.RaceSponsor>(
    idx: number,
    key: K,
    value: Race.RaceSponsor[K],
  ) => {
    const _race = _.cloneDeep(race);
    if (_race.sponsors[idx] === undefined) return;
    _race.sponsors[idx][key] = value;
    setRace(_race);
  };

  const handleSponsorImageChange = async <K extends keyof Race.RaceSponsor>(
    idx: number,
    key: 'logoImageUrl' | 'whiteLogoImageUrl',
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const _race = _.cloneDeep(race);
    const temp = await handleChangeSingleFile(e, {});
    if (_race.sponsors[idx] === undefined) return;
    _race.sponsors[idx][key] = temp[key];
    setRace(_race);
  };

  const handleLeageImageFilterLogoImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const _imageFilters = _.cloneDeep(imageFilters);
    const { name } = e.target;
    const temp = await handleChangeSingleFile(e, {});

    _imageFilters.forEach((filter) => {
      const logoStamp = filter.stamps.find(
        ({ type }) => type === 'LOGO_STAMP',
      ) as RaceImageFilterLogoStamp | undefined;
      if (!logoStamp) return;
      logoStamp.imageUrl = temp[name];
    });

    setImageFilters(_imageFilters);
  };

  const handleLeageImageFilterNumberChange = async (
    key: 'width' | 'height',
    value: string,
  ) => {
    const _imageFilters = _.cloneDeep(imageFilters);

    _imageFilters.forEach((filter) => {
      const logoStamp = filter.stamps.find(
        ({ type }) => type === 'LOGO_STAMP',
      ) as RaceImageFilterLogoStamp;

      if (!logoStamp) return;
      logoStamp[key] = Number(value);
    });

    setImageFilters(_imageFilters);
  };

  const setSponsorLogoForAllLeagues = () => {
    if (
      window.confirm('모든 리그의 스폰서 로고를 동일하게 적용하시겠습니까?')
    ) {
      const _imageFilters = _.cloneDeep(imageFilters);
      if (_imageFilters.length === 0) {
        alert('이미지 필터가 없습니다.');
        return;
      }
      const sponsorInfo = _imageFilters[0].stamps.find(
        ({ type }) => type === 'LOGO_STAMP',
      ) as RaceImageFilterLogoStamp | undefined;

      _imageFilters.forEach((filter) => {
        const logoStamp = filter.stamps.find(
          ({ type }) => type === 'LOGO_STAMP',
        ) as RaceImageFilterLogoStamp;

        if (!sponsorInfo) return;

        logoStamp.imageUrl = sponsorInfo.imageUrl;
        logoStamp.width = sponsorInfo.width;
        logoStamp.height = sponsorInfo.height;
      });

      setImageFilters(_imageFilters);
    }
  };

  const logoStamp = imageFilters[0]?.stamps.find(
    ({ type }) => type === 'LOGO_STAMP',
  ) as RaceImageFilterLogoStamp | undefined;

  return (
    <>
      <h3>
        후원사 정보
        <Label
          style={{ marginLeft: 10, cursor: 'pointer' }}
          content={'추가'}
          onClick={addSponsor}
        />
      </h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>no</Table.HeaderCell>
            <Table.HeaderCell>후원사명</Table.HeaderCell>
            <Table.HeaderCell>구분</Table.HeaderCell>
            <Table.HeaderCell>로고</Table.HeaderCell>
            <Table.HeaderCell>화이트로고 (512*78)</Table.HeaderCell>
            <Table.HeaderCell>삭제</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {race.sponsors?.map((sponsor, idx) => (
            <Table.Row key={`${sponsor.id}-${idx}`}>
              <Table.Cell verticalAlign="top">{idx + 1}</Table.Cell>
              <Table.Cell verticalAlign="top">
                <input
                  value={sponsor.name}
                  onChange={(e) =>
                    handleSponsorChange(idx, 'name', e.target.value)
                  }
                />
              </Table.Cell>
              <Table.Cell verticalAlign="top">
                <Dropdown
                  placeholder="후원사 구분을 선택하세요"
                  fluid
                  search
                  selection
                  options={sponsorTypeOptions}
                  value={sponsor.type}
                  onChange={(__, { value }) =>
                    handleSponsorChange(
                      idx,
                      'type',
                      value as Race.RaceSponsorType,
                    )
                  }
                />
              </Table.Cell>
              <Table.Cell verticalAlign="top">
                <input
                  type="file"
                  name="logoImageUrl"
                  onChange={(e) =>
                    handleSponsorImageChange(idx, 'logoImageUrl', e)
                  }
                />
                <Image src={sponsor.logoImageUrl} size="small" />
                <LogoPreview />
              </Table.Cell>
              <Table.Cell verticalAlign="top">
                <input
                  type="file"
                  name="whiteLogoImageUrl"
                  onChange={(e) =>
                    handleSponsorImageChange(idx, 'whiteLogoImageUrl', e)
                  }
                />
                {!!sponsor.whiteLogoImageUrl && (
                  <div
                    style={{ padding: 10, backgroundColor: colors.GRAY_500 }}
                  >
                    <Image src={sponsor.whiteLogoImageUrl} size="small" />
                  </div>
                )}
                <WhiteLogoPreview />
              </Table.Cell>
              <Table.Cell verticalAlign="top">
                <Label content={'삭제'} onClick={() => deleteSponsor(idx)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <h4 style={{ margin: 0 }}>카메라 필터 후원사 이미지</h4>
      <FilterPreview />
      <Label
        style={{ marginLeft: 10, cursor: 'pointer' }}
        content={'동일 후원사 이미지 모든 리그에 적용'}
        onClick={setSponsorLogoForAllLeagues}
      />
      <Row
        style={{
          gap: '10px',
        }}
      >
        <div>
          <label>후원사 로고 이미지</label>
          <input
            type="file"
            name="logoImageUrl"
            onChange={(e) => handleLeageImageFilterLogoImageChange(e)}
          />
          <div style={{ padding: 10, backgroundColor: colors.GRAY_500 }}>
            <Image src={logoStamp?.imageUrl || ''} size="small" />
          </div>
        </div>
        <div>
          <label>후원사 로고 가로 사이즈 (정수만 입력 가능)</label>
          <input
            type="number"
            value={
              imageFilters?.length > 0 && logoStamp ? logoStamp?.width : 160
            }
            onChange={(e) =>
              handleLeageImageFilterNumberChange('width', e.target.value)
            }
          />
        </div>
        <div>
          <label>후원사 로고 세로 사이즈 (정수만 입력 가능)</label>
          <input
            type="number"
            value={
              imageFilters?.length > 0 && logoStamp ? logoStamp?.height : 20
            }
            onChange={(e) =>
              handleLeageImageFilterNumberChange('height', e.target.value)
            }
          />
        </div>
      </Row>
    </>
  );
};

export default SponsorField;
