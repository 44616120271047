import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { colors } from '../../../../../constant/colors';
import { generateId } from '../../../../../utils/number';
import { handleChangeSingleFile } from '../../../../../utils/uploadImage';
import * as S from '../../../Style';
import Preview from './Preview/Preview';
import { RaceSticker } from 'src/types/race.types';
import _ from 'lodash';
import { Race } from '@types';

const StampField = ({
  stampStickers,
  setStampStickers,
}: {
  stampStickers: RaceSticker[];
  setStampStickers: React.Dispatch<React.SetStateAction<RaceSticker[]>>;
}) => {
  const addLeagueSticker = () => {
    const _stampStickers = _.cloneDeep(stampStickers);
    _stampStickers.push({
      id: generateId(),
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 100,
      height: 100,
      imageUrl: '',
    });
    setStampStickers(_stampStickers);
  };

  const deleteLeagueSticker = (stickerIdx: number) => {
    const _stampStickers = _.cloneDeep(stampStickers);
    _stampStickers.splice(stickerIdx, 1);
    setStampStickers(_stampStickers);
  };

  const handleLeagueStickerChange = <K extends keyof Race.RaceSticker>(
    stickerIdx: number,
    key: K,
    value: Race.RaceSticker[K],
  ) => {
    const _stampStickers = _.cloneDeep(stampStickers);
    _stampStickers[stickerIdx][key] = value;
    setStampStickers(_stampStickers);
  };

  const handleLeagueStickerImageChange = async (
    stickerIdx: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const _stampStickers = _.cloneDeep(stampStickers);
    const { imageUrl } = await handleChangeSingleFile(e, {});
    _stampStickers[stickerIdx].imageUrl = imageUrl;
    setStampStickers(_stampStickers);
  };

  return (
    <>
      <h3>각 리그별 스탬프 정보</h3>
      <Preview />

      <S.ButtonInForm content={'추가'} onClick={addLeagueSticker} />
      <Grid>
        <Grid.Row>
          {stampStickers?.map((sticker, i) => (
            <div
              key={sticker.id}
              style={{
                border: `1px solid ${colors.GRAY_500}`,
                borderRadius: 4,
                marginTop: 10,
                padding: 10,
              }}
            >
              <input
                type="file"
                name="imageUrl"
                onChange={(e) => handleLeagueStickerImageChange(i, e)}
              />
              {!!sticker.imageUrl && (
                <div>
                  <div
                    style={{
                      padding: 10,
                      backgroundColor: colors.GRAY_500,
                    }}
                  >
                    <Image src={sticker.imageUrl} size="small" />
                  </div>
                  <div>*위치 설정 안할거면 빈 값으로 설정 필요</div>
                  <div>
                    <label>위</label>
                    <input
                      value={sticker.top}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) return;
                        handleLeagueStickerChange(
                          i,
                          'top',
                          Number(e.target.value),
                        );
                      }}
                    />
                  </div>
                  <div>
                    <label>왼쪽</label>
                    <input
                      value={sticker.left}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) return;
                        handleLeagueStickerChange(
                          i,
                          'left',
                          Number(e.target.value),
                        );
                      }}
                    />
                  </div>
                  <div>
                    <label>오른쪽</label>
                    <input
                      value={sticker.right}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) return;
                        handleLeagueStickerChange(
                          i,
                          'right',
                          Number(e.target.value),
                        );
                      }}
                    />
                  </div>
                  <div>
                    <label>아래쪽</label>
                    <input
                      value={sticker.bottom}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) return;
                        handleLeagueStickerChange(
                          i,
                          'bottom',
                          Number(e.target.value),
                        );
                      }}
                    />
                  </div>
                  <div>
                    <label>가로</label>
                    <input
                      value={sticker.width}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) return;
                        handleLeagueStickerChange(
                          i,
                          'width',
                          Number(e.target.value),
                        );
                      }}
                    />
                  </div>
                  <div>
                    <label>세로</label>
                    <input
                      value={sticker.height}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) return;
                        handleLeagueStickerChange(
                          i,
                          'height',
                          Number(e.target.value),
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              <S.ButtonInForm
                content={'삭제'}
                onClick={() => deleteLeagueSticker(i)}
              />
            </div>
          ))}
        </Grid.Row>
      </Grid>
    </>
  );
};

export default StampField;
