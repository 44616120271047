export const enum ApiType {
  go = 'GO',
  python = 'PYTHON',
  media = 'MEDIA',
}

export const apiType = {
  go: 'GO',
  python: 'PYTHON',
  media: 'MEDIA',
} as const;
