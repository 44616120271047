import api, { apiMedia, apiGo, setAuthToken, login } from '../../api';
import { setLocal } from '../../global/local';
import { setRoles, setToken, setUser } from '../App/App.action';
import moment from 'moment';

const signIn = (email, password) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { user, token } = await login({ email, password });
      if (moment(user.updatedAt).isAfter(moment().subtract(180, 'd'))) {
        setTokenAndUser(dispatch, user, token);
        setLocal('newToken', token);
      }
      resolve([user, token]);
    } catch (error) {
      const errorMessage = error.message;
      console.log(errorMessage);
      reject();
    }
  });

const updateMe = (user, token) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      setTokenAndUser(dispatch, user, token);
    } catch (e) {
      const errorMessage = error.message;
      console.log(errorMessage);
      reject();
    }
  });

const setTokenAndUser = (dispatch, user, token) => {
  setAuthToken(api, token);
  setAuthToken(apiMedia, token);
  setAuthToken(apiGo, token);
  dispatch(setToken(token));
  dispatch(setRoles(user.roles));
  dispatch(setUser(user));
};

export default (dispatch) => ({
  signIn: (email, password) => dispatch(signIn(email, password)),
  updateMe: (user, token) => dispatch(updateMe(user, token)),
});
