export const eventType = {
  register_challenge: '챌린지 신청',
  information: '단순 정보 노출',
  redirect: '리다이렉트',
};

export const newEventType = {
  challenge: '챌린지 큐레이션',
  race: '랜선대회 큐레이션',
  information: '단순 정보 노출',
};

export const newEventTypeList = [
  { key: 'challenge', label: newEventType.challenge },
  { key: 'race', label: newEventType.race },
  { key: 'information', label: newEventType.information },
];

export const moduleType = {
  imageList: 'ImageList',
  countDown: 'CountDown',
  billBoard: 'BillBoard',
  sectionTitle: 'SectionTitle',
  itemList: 'ItemList',
  bannerList: 'AttachedBannerList',
  button: 'Button',
  shareBoard: 'ShareBoard',
  nativeShareButton: 'NativeShareButton',
  divider: 'Divider',
  youtubePlayer: 'YoutubePlayer',
  raceCoupon: 'RaceCoupon',
  raceCouponAllDownload: 'RaceCouponAllDownload',
  preAlarmList: 'PreAlarmList',
};

export const modules = [
  { name: moduleType.imageList, data: {}, text: '이미지' },
  { name: moduleType.countDown, data: {}, text: '카운트다운' },
  { name: moduleType.billBoard, data: {}, text: '참가자/구매자 숫자' },
  { name: moduleType.sectionTitle, data: {}, text: '섹션 제목' },
  { name: moduleType.itemList, data: {}, text: '챌린지/랜선대회 목록' },
  { name: moduleType.bannerList, data: {}, text: '배너 목록' },
  { name: moduleType.button, data: {}, text: '버튼' },
  { name: moduleType.shareBoard, data: {}, text: '공유하기 모듈 (웹용)' },
  {
    name: moduleType.nativeShareButton,
    data: {},
    text: '공유하기 버튼 (앱용)',
  },
  { name: moduleType.divider, data: {}, text: '구분선' },
  { name: moduleType.youtubePlayer, data: {}, text: '유튜브 플레이어' },
  { name: moduleType.raceCoupon, data: {}, text: '랜선대회 쿠폰' },
  { name: moduleType.raceCouponAllDownload, data: {}, text: '쿠폰 전체 받기' },
  { name: moduleType.preAlarmList, data: {}, text: '오픈 알림 목록' },
];
